import React from 'react'
import { Modal } from 'antd'
import { navigateToManageBilling } from '../Settings'
import createActionDialog from './actionDialog'

export function createBillingModal(session) {
    const { openDialog, closeDialog, DialogComponent } = createActionDialog(
        async () => await navigateToManageBilling(session),
        () => {},
        'Dit abonnement er inaktivt',
        'Venligst gå til betalingssiden og aktiver din profil',
        'Betalings siden',
        'Annuller',
        'primary',
        'info'
        // true
    )

    return { openDialog, DialogComponent }
}
