import { message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { APIFunctions } from '../../lib/apiFunctions'
import { IAddPlaceAPI, PlaceType, userLocation } from '../../lib/interfaces'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import AutoComplete from './AutoComplete'
import countryData from '../../lib/countries.json'
import { IconButton, ListItemSecondaryAction, Icon, Button } from '@mui/material'
import Chip from '@mui/material/Chip'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import { formatText, layoutDictKeyFormat, UserLocationDate } from '../../lib/dateFunctions'
import { isSameDay, toDate } from 'date-fns'
import dayjs from 'dayjs'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { navigate } from 'gatsby'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
    })
)

function populateUserLocationDates(userLocationDates, setUserLocationDates, current_date) {
    if (!(userLocationDates && setUserLocationDates && current_date)) {
        return
    }
    if (
        !userLocationDates.find(
            (uld) =>
                formatText(toDate(new Date(uld.target_date)), layoutDictKeyFormat) ===
                formatText(toDate(new Date(current_date)), layoutDictKeyFormat)
        )
    ) {
        setUserLocationDates([
            ...userLocationDates,
            { target_date: toDate(new Date(current_date)), location_count: 1 },
        ])
    } else {
        setUserLocationDates(
            userLocationDates.map((uld) => {
                if (
                    formatText(toDate(new Date(uld.target_date)), layoutDictKeyFormat) ===
                    formatText(toDate(new Date(current_date)), layoutDictKeyFormat)
                ) {
                    return {
                        ...uld,
                        location_count: uld.location_count + 1,
                    }
                }
                return uld
            })
        )
    }
}

function deleteUserLocationDatesDate(userLocationDates, setUserLocationDates, current_date) {
    if (!userLocationDates) return
    let hasMore = true
    setUserLocationDates(
        userLocationDates.map((uld) => {
            if (
                formatText(toDate(new Date(uld.target_date)), layoutDictKeyFormat) ===
                formatText(toDate(new Date(current_date)), layoutDictKeyFormat)
            ) {
                if (uld.location_count > 1) {
                    return {
                        ...uld,
                        location_count: uld.location_count - 1,
                    }
                } else {
                    hasMore = false
                }
            }
            return uld
        })
    )
    if (!hasMore) {
        setUserLocationDates(
            userLocationDates.filter(
                (uld) =>
                    formatText(toDate(new Date(uld.target_date)), layoutDictKeyFormat) !==
                    formatText(toDate(new Date(current_date)), layoutDictKeyFormat)
            )
        )
    }
}

export function WhereAbouts({
    dateClicked,
    apiFunctions,
    userId,
    dropdownOpen,
    setDropdownOpen,
    reportChange,
    userLocationDates,
    setUserLocationDates,
    setIsBackgroundLoading,
}: {
    dateClicked: number | Date
    apiFunctions: APIFunctions
    userId: string
    dropdownOpen: boolean
    setDropdownOpen: (i: boolean) => void
    setIsBackgroundLoading?: (i: boolean) => void
    userLocationDates?: UserLocationDate[]
    setUserLocationDates?: (input: UserLocationDate[]) => void
    reportChange?
}) {
    const [inputValue, setInputValue] = React.useState('')
    const [searchResult, setSearchResult] = React.useState<PlaceType | null>(null)
    const [countriesSelected, setCountriesSelected] = useState<userLocation[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [searchSessionToken, setSearchSessionToken] = useState<string>(uuidv4())
    const [previewDocParseVisible, setPreviewDocParseVisible] = useState<boolean>(false)
    const classes = useStyles()

    useEffect(() => {
        setLoading(true)
        setSearchResult(null)
        setDropdownOpen(false)
        setCountriesSelected([])
        fetchLocations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateClicked, apiFunctions, userId])

    async function fetchLocations() {
        apiFunctions
            ?.getUserLocationForDate(userId, dateClicked)
            .then((data) => {
                if (
                    data.length > 0 &&
                    userLocationDates &&
                    userLocationDates.find((ud) =>
                        isSameDay(new Date(ud.target_date), new Date(dateClicked))
                    )
                ) {
                    setUserLocationDates([
                        ...userLocationDates,
                        {
                            target_date: formatText(dateClicked, layoutDictKeyFormat),
                            location_count: data.length,
                        },
                    ])
                }
                setCountriesSelected(data)
            })
            .catch((err) => {
                message.error(err?.data?.detail ?? err.toString())
            })
            .finally(() => {
                setLoading(false)
            })
    }

    async function deleteLocation(country: userLocation) {
        setLoading(true)
        await apiFunctions.deleteUserLocationForDate(userId, dateClicked, country.user_location_id)
        if (setIsBackgroundLoading) {
            setIsBackgroundLoading(true)
            setIsBackgroundLoading(false)
        }
        await fetchLocations()
        deleteUserLocationDatesDate(userLocationDates, setUserLocationDates, dateClicked)
        setLoading(false)
    }

    async function confirmLocationSuggestion(country: userLocation) {
        setLoading(true)
        await apiFunctions.confirmUserLocation(userId, dateClicked, country.user_location_id)
        setCountriesSelected(
            countriesSelected.map((cs) => {
                if (cs.user_location_id === country.user_location_id) {
                    return {
                        ...cs,
                        is_confirmed: true,
                    }
                }
                return cs
            })
        )
        setPreviewDocParseVisible(false)
        setLoading(false)
    }

    useEffect(() => {
        if (!(searchResult && dateClicked && userId && apiFunctions?.addUserLocation)) return
        setLoading(true)
        const apiObj: IAddPlaceAPI = {
            place_id: searchResult.location_id,
            place_type: 'google-prediction',
            session_token: searchSessionToken,
        }
        apiFunctions
            .addUserLocation(userId, dateClicked, apiObj)
            .then((d) => {})
            .finally(() => {
                fetchLocations().finally(() => {
                    setInputValue('')
                    setSearchSessionToken(uuidv4())
                    populateUserLocationDates(userLocationDates, setUserLocationDates, dateClicked)
                    setLoading(false)
                })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResult])

    useEffect(() => {
        if (!reportChange) {
            return
        }
        reportChange({
            targetDate: dateClicked,
            action: 'newLocations',
            newLocations: countriesSelected,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countriesSelected])

    function renderSecondary(data: userLocation, countryMeta) {
        if (data.is_geo_snapshot) {
            return (
                <>
                    {countryMeta?.name}{' '}
                    <Chip
                        variant="outlined"
                        size="small"
                        avatar={<WhereToVoteIcon />}
                        label={
                            data?.snapshot_timestamp
                                ? `GS ${dayjs(data?.snapshot_timestamp).format('HH:mm')}`
                                : 'GeoSnapshot'
                        }
                        onDelete={
                            !!data?.statement_ll_documentation_id
                                ? () => {
                                      navigate(
                                          `/app/?view_document_id=${
                                              data?.statement_ll_documentation_id
                                          }&calendar_day=${dayjs(data?.snapshot_timestamp).format(
                                              'YYYY-MM-DD'
                                          )}`
                                      )
                                      reportChange({
                                          action: 'openDocument',
                                          documentationId: data?.statement_ll_documentation_id,
                                      })
                                  }
                                : undefined
                        }
                        deleteIcon={<PictureAsPdfIcon />}
                    />
                </>
            )
        } else if (data.is_doc_parse_suggestion) {
            const footer = [
                <Button
                    variant="contained"
                    onClick={() => {
                        setPreviewDocParseVisible(false)
                    }}
                    style={{ marginRight: 5 }}
                >
                    Return
                </Button>,
                <Button variant="contained" style={{ marginRight: 5 }}>
                    <a
                        href={data.sugg_url}
                        download={`doc-${data.doc_parse_suggestion_id}.${data.file_name_ending}`}
                    >
                        Download file
                    </a>
                </Button>,
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        confirmLocationSuggestion(data)
                    }}
                >
                    Approve suggestion
                </Button>,
            ]
            return (
                <>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        endIcon={<FindInPageIcon />}
                        onClick={() => {
                            setPreviewDocParseVisible(true)
                        }}
                    >
                        {data.is_confirmed ? 'Found in doc' : 'Suggestion - confirm?'}
                    </Button>
                    <Modal
                        visible={previewDocParseVisible}
                        title={'Image'}
                        footer={data.is_confirmed ? footer.slice(0, -1) : footer}
                        onCancel={() => {
                            setPreviewDocParseVisible(false)
                        }}
                    >
                        <img
                            alt={
                                'File is uploaded but could not render. ' +
                                'The file is still included in your report! ' +
                                'You can download the file to view.'
                            }
                            style={{ width: '100%' }}
                            src={data.sugg_url}
                        />
                    </Modal>
                </>
            )
        }
        return countryMeta?.name
    }

    return (
        <div>
            <AutoComplete
                inputValue={inputValue}
                setInputValue={setInputValue}
                searchResult={searchResult}
                setSearchResult={setSearchResult}
                loading={loading}
                setLoading={setLoading}
                apiFunctions={apiFunctions}
                userId={userId}
                sessionToken={searchSessionToken}
            />
            <List className={classes.root}>
                {countriesSelected.map((c, idx) => {
                    const thisCountryData = countryData[c.country_code.toString().toUpperCase()]
                    return (
                        <ListItem key={`prediction-${idx}`}>
                            <ListItemAvatar>
                                <Icon>{thisCountryData?.emoji}</Icon>
                            </ListItemAvatar>
                            <ListItemText
                                primary={c.location_name}
                                secondary={renderSecondary(c, thisCountryData)}
                            />
                            <ListItemSecondaryAction onClick={() => deleteLocation(c)}>
                                <IconButton edge="end" aria-label="delete" size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
        </div>
    )
}
