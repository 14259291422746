import { Button, Divider, message, Modal, Popconfirm, Upload } from 'antd'
import { isSameDay, toDate } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons'
import './style.less'
import { PDFViewer } from './PDFRender'
import { isAndroid } from 'react-device-detect'
import { cameraModal, IFileList } from '../../lib/interfaces'
import DocFileItem from './IncludeDocSwitch'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { formatText, layoutDictKeyFormat } from '../../lib/dateFunctions'
import { createBillingModal } from '../modals/purchaseModal'
import { useSessionIfExists } from '../../hooks/useAuth'
import { useLocation } from '@reach/router'
import { Box, CircularProgress } from '@mui/material'

function getBase64(file) {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })
}

export function Camera({
    dateClicked,
    visible,
    setVisible,
    setDocDates,
    docDates,
    title,
    subTitle = 'Use the switch to include or exclude documentation in compliance reports',
    reportChange,
    allowChanges = true,
    apiFunctions,
    userId,
}: cameraModal) {
    const [fileList, setFileList] = useState<IFileList[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRefreshingDocs, setIsRefreshingDocs] = useState<boolean>(false)
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const [previewImage, setPreviewImage] = useState<any>(null)
    const [previewFile, setPreviewFile] = useState<any>(null)
    const [popConfirmVisible, setPopConfirmVisible] = useState<boolean>(false)
    const [fileToDelete, setFileToDelete] = useState<any>(null)
    const [fileInProgress, setFileInProgress] = useState<string | null>(null)
    const { session } = useSessionIfExists()
    const { openDialog: openBilllingModal, DialogComponent: BillingModal } =
        createBillingModal(session)
    const { search } = useLocation()

    async function refreshDocs() {
        if (!dateClicked || !visible || !userId) return
        setIsRefreshingDocs(true)
        const data = await apiFunctions.getDocumentation(userId, dateClicked)
        if (data?.documents) {
            setFileList(data?.documents?.filter((d) => !d?.is_linked_to_location) || [])
        }
        setIsRefreshingDocs(false)
    }

    const handlePreview = async (file: IFileList | any) => {
        if (!file.url && !file.preview) {
            file.preview = (await getBase64(file.originFileObj)).toString()
        }
        setPreviewFile(file)
        const fileURL = file.url || file.preview
        if (file.type === 'application/pdf') {
            setPreviewImage(<PDFViewer filePath={fileURL} />)
        } else if (file.type === 'application/zip') {
            setPreviewImage(<h3>No preview for compressed files</h3>)
        } else {
            setPreviewImage(
                <img
                    alt={
                        'File is uploaded but could not render. ' +
                        'The file is still included in your report! ' +
                        'You can download the file to view.'
                    }
                    style={{ width: '100%' }}
                    src={fileURL}
                />
            )
        }
        setPreviewVisible(true)
    }

    useEffect(() => {
        if (previewFile != null) return
        const parameters = new URLSearchParams(search)
        const documentId = parameters.get('view_document_id')
        if (documentId) {
            const fileToPreview = fileList.find((file) => file.uid === documentId)
            if (fileToPreview) {
                handlePreview(fileToPreview)
            }
        }
    }, [search, fileList])

    useEffect(() => {
        refreshDocs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateClicked, visible, apiFunctions, userId])

    if (!dateClicked || !visible) return null

    const closeModal = (e, force = false) => {
        if (fileInProgress && !force) {
            message.warning('Please do not close this while uploading')
            return
        }
        setVisible(false)
        setIsLoading(false)
        setPreviewVisible(false)
        setPreviewImage(null)
        setPreviewFile(null)
        setPopConfirmVisible(false)
        setFileToDelete(null)
    }

    const handleCancel = () => {
        const parameters = new URLSearchParams(search)
        const documentId = parameters.get('view_document_id')
        const calendarDay = parameters.get('calendar_day')
        if (documentId) {
            let newState = '?'
            if (calendarDay) {
                newState = `?calendar_day=${calendarDay}`
            }
            window.history.replaceState(null, null as any, newState)
        }
        setPreviewVisible(false)
    }

    const handleCancelDelete = () => {
        setPopConfirmVisible(false)
        setFileToDelete(null)
    }

    function beforeUpload(file) {
        setFileInProgress(file.uid)
        const isLt15M = file.size / 1024 / 1024 < 15
        if (!isLt15M) {
            message.error('File must be smaller than 15MB!')
        }
        return isLt15M
    }

    const handleChange = async (filelist: UploadChangeParam<UploadFile<any>>) => {
        const srcFList: UploadFile<any>[] = filelist.fileList
        let fList = []
        if (srcFList.length < fileList.length) {
            return
        }
        srcFList.forEach((f) => {
            let res = f
            if (f.uid === fileInProgress && f.status === 'done') {
                setFileInProgress(null)
                if (
                    fileList.length === 1 &&
                    !docDates.find((dd) => dd.documentation_date === dateClicked)
                ) {
                    setDocDates([
                        ...docDates,
                        { documentation_date: dateClicked, date_is_included_in_report: true },
                    ])
                }
            }
            if (Object.keys(f).includes('response')) {
                res = {
                    ...f.response,
                    name: 'Doc',
                }
            }
            fList.push(res)
        })
        if (reportChange) {
            reportChange({
                targetDate: dateClicked,
                action: 'fileListChange',
                newFileList: fList,
            })
        }
        setFileList(fList)
    }

    const promptDelete = (file) => {
        setFileToDelete(file)
        setPopConfirmVisible(true)
    }

    const deleteDoc = async (e: any = null, file = fileToDelete) => {
        if (file.status === 'error') return
        setIsLoading(true)
        const uidToDelete = file.response?.uid || file.uid
        try {
            await apiFunctions.deleteDocumentation(userId, uidToDelete)
            if (fileList.length === 1) {
                setDocDates(
                    docDates.filter(
                        (d) =>
                            !isSameDay(
                                new Date(d.documentation_date),
                                toDate(new Date(dateClicked))
                            )
                    )
                )
            }
            if (reportChange)
                reportChange({
                    targetDate: dateClicked,
                    action: 'delete',
                    fileId: uidToDelete,
                    isIncluded: false,
                })
            setFileList(fileList.filter((f) => f.uid !== file.uid))
            setFileToDelete(null)
        } catch (error) {
            if (error.status && error.status === 402) {
                openBilllingModal()
            }
        }
        setIsLoading(false)
        setPopConfirmVisible(false)
    }

    const uploadButton = (description) => (
        <div className="autotest-uplpoad-area-button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{description}</div>
        </div>
    )

    const renderDoc = (originNode, file: IFileList | any, currFileList) => {
        if (!allowChanges) return originNode
        return DocFileItem(
            originNode,
            file,
            currFileList,
            reportChange,
            dateClicked,
            visible,
            apiFunctions,
            userId,
            setFileList
        )
    }

    const uploadRender = () => {
        return !isAndroid ? (
            <Upload
                action={apiFunctions.getUploadDocPath(userId, dateClicked)}
                headers={{
                    authorization: `Bearer ${apiFunctions.getStoredAuthToken()}`,
                }}
                method="PUT"
                beforeUpload={beforeUpload}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                accept=".png,.docx,.doc,.jpg,.jpeg,.pdf,.zip,.pkpass"
                onChange={handleChange}
                onRemove={promptDelete}
                itemRender={renderDoc}
                className="autotest-upload-image-input"
                showUploadList={{ showRemoveIcon: allowChanges }}
            >
                {allowChanges && fileList.length < 8 ? uploadButton('Upload') : null}
            </Upload>
        ) : (
            <>
                <Upload
                    action={apiFunctions.getUploadDocPath(userId, dateClicked)}
                    headers={{
                        authorization: `Bearer ${apiFunctions.getStoredAuthToken()}`,
                    }}
                    method="PUT"
                    beforeUpload={beforeUpload}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    accept=".png,.docx,.doc,.jpg,.jpeg,.pdf,.zip,.pkpass"
                    onChange={handleChange}
                    onRemove={promptDelete}
                    itemRender={renderDoc}
                >
                    {allowChanges && fileList.length < 8 ? uploadButton('Upload File') : null}
                </Upload>
                <Upload
                    action={apiFunctions.getUploadDocPath(userId, dateClicked)}
                    headers={{
                        authorization: `Bearer ${apiFunctions.getStoredAuthToken()}`,
                    }}
                    method="PUT"
                    beforeUpload={beforeUpload}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    accept=".png,.jpg" //,.jpeg,.pdf,.zip,.pkpass'
                    onChange={handleChange}
                    onRemove={promptDelete}
                    showUploadList={false}
                >
                    {allowChanges && fileList.length < 8 ? uploadButton('Upload Image') : null}
                </Upload>
            </>
        )
    }

    return (
        <Modal visible={visible} onCancel={closeModal} onOk={closeModal} footer={[]}>
            {BillingModal}
            <div className="menuRow">{title && title}</div>
            <div>
                <h4>{subTitle}</h4>
                {isRefreshingDocs && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <Divider />
            </div>
            <Popconfirm
                title="Cofirm delete?"
                visible={popConfirmVisible}
                onConfirm={deleteDoc}
                okButtonProps={{ loading: isLoading }}
                onCancel={handleCancelDelete}
            >
                {uploadRender()}
            </Popconfirm>
            {fileInProgress && (
                <>
                    <Button
                        style={{ marginTop: 30 }}
                        danger
                        icon={<CloseCircleOutlined />}
                        onClick={() => {
                            closeModal(null, true)
                        }}
                    >
                        Cancel Upload
                    </Button>
                </>
            )}
            <Modal
                visible={previewVisible}
                title={'Image'}
                footer={[
                    <Button onClick={handleCancel}>Return</Button>,
                    <Button type="primary">
                        <a
                            href={previewFile ? previewFile.url || previewFile.preview : ''}
                            download={`doc-${formatText(
                                toDate(new Date(dateClicked)),
                                layoutDictKeyFormat
                            )}.${previewFile ? String(previewFile.type).split('/')[1] : 'jpg'}`}
                        >
                            Download file
                        </a>
                    </Button>,
                ]}
                onCancel={handleCancel}
            >
                {previewImage}
            </Modal>
        </Modal>
    )
}
