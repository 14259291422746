import { Button, Divider, Statistic, Tag, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { httpPost } from '../../services/http'
import { Session } from '../../types/session'
import { CheckCircleTwoTone, EditOutlined } from '@ant-design/icons'
import { endOfToday, isAfter, isBefore, startOfToday, toDate } from 'date-fns'
import { formatText } from '../../lib/dateFunctions'

const { Search } = Input

export interface IUserInfo {
    session: Session
    useBareFeatures: boolean
}

const getExpireStyles = (session) => {
    if (!session.user) {
        return {}
    }
    return isBefore(toDate(session.user?.current_period_end), startOfToday())
        ? {
              color: 'red',
          }
        : {}
}

export function UserInfo({ session, useBareFeatures }: IUserInfo) {
    const [status, setStatus] = useState<boolean | null>(null)
    const [changingName, setChangingName] = useState<boolean>(false)
    const [changingNameLoading, setChangingNameLoading] = useState<boolean>(false)
    const [userName, setUserName] = useState<string>(session.user?.name)
    const [userEmail, setUserEmail] = useState<string>(session.user?.email)
    const [expireStyles, setExpireStyles] = useState<any>(getExpireStyles(session))

    useEffect(() => {
        setExpireStyles(getExpireStyles(session))
        setUserName(session.user?.name)
        setUserEmail(session.user?.email)
    }, [session])

    const sendVerificationMail = async () => {
        setStatus(false)
        try {
            await httpPost('/auth/send-verification-email')
        } catch {
            console.log('err')
        } finally {
            setStatus(true)
        }
    }

    const updateName = async (value: string) => {
        setChangingNameLoading(true)
        try {
            await httpPost(`/auth/change-user-name/${value}`)
            setUserName(value)
            message.success('Changed name!')
        } catch (error) {
            // message.error('something went wrong');
        } finally {
            setChangingName(false)
            setChangingNameLoading(false)
        }
    }

    return (
        <div>
            {(changingName && (
                <Search
                    placeholder={userName || 'Enter your name'}
                    className="ll-period-config-title"
                    allowClear
                    loading={changingNameLoading}
                    enterButton={<EditOutlined />}
                    onSearch={updateName}
                />
            )) || (
                <div onClick={() => setChangingName(true)}>
                    <Statistic title="Name" value={userName} />
                </div>
            )}
            <Statistic
                title="E-mail"
                value={userEmail}
                prefix={
                    !session.user?.is_mail_verified && (
                        <Button
                            onClick={sendVerificationMail}
                            disabled={status !== null}
                            loading={status === false}
                            icon={status === true && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                        >
                            Re-send verification mail
                        </Button>
                    )
                }
                suffix={
                    (session.user?.is_mail_verified && <Tag color="green">Verified</Tag>) || (
                        <Tag color="orange">Pending verification</Tag>
                    )
                }
            />
            {!useBareFeatures && (
                <Statistic
                    title="Subscription Reset"
                    valueStyle={expireStyles}
                    value={formatText(toDate(session.user?.current_period_end), 'd. MMM yyyy')}
                    suffix={
                        !session.user?.has_paid &&
                        isAfter(session.user.current_period_end, endOfToday()) && (
                            <Tag color="blue">Free Trial</Tag>
                        )
                    }
                />
            )}
            <Divider />
        </div>
    )
}
