import { Button, DatePicker, Form, message, Modal, Tag, Upload } from 'antd'
import React, { useState } from 'react'
import { API_URL, getAuthHeader, getCommonHeaders, httpGet, httpPost } from '../../services/http'
import './style.less'
import { CloudUploadOutlined } from '@ant-design/icons'
import { IRosterPeriodRes } from './RosterCard'
import { IGraphPoint } from '../../lib/interfaces'
import axios, { AxiosRequestConfig } from 'axios'
import { layoutDictKeyFormat } from '../../lib/dateFunctions'

const { RangePicker } = DatePicker

interface IRosterModal {
    visible: boolean
    setVisible: any
    setData: (data: any) => void
    updateData: (partial?: string, targetDate?, alterFun?: (dp: IGraphPoint) => IGraphPoint) => void
}

const rangeConfig = {
    rules: [
        {
            type: 'array' as const,
            required: true,
            message: 'Please select time!',
        },
    ],
}
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
}

export default function RosterUpload({ visible, setVisible, setData, updateData }: IRosterModal) {
    const [loading, setLoading] = useState(false)
    const [pdfFileUid, setPdfFileUid] = useState(null)
    const [csvFileUid, setCsvFileUid] = useState(null)

    function closeModal() {
        setPdfFileUid(null)
        setCsvFileUid(null)
        setVisible(false)
    }

    async function onFinish(fieldsValue: any) {
        setLoading(true)
        const rangeValue = fieldsValue['range-picker']
        let rosterConfig = {
            date_from: rangeValue[0].format('YYYY-MM-DD'),
            date_to: rangeValue[1].format('YYYY-MM-DD'),
            file_pdf: pdfFileUid,
            file_csv: csvFileUid,
        }
        try {
            const res = await httpPost('/roster', rosterConfig)
            if (res.ok) {
                const res = await httpGet<IRosterPeriodRes>('/roster')
                if (res.data && res.data.roster_periods) {
                    setData(res.data.roster_periods)
                    message.success('Roster Uploaded!')
                    // await updateData('roster');
                    if (typeof window !== 'undefined') {
                        window.location.reload()
                    }
                    setVisible(false)
                }
            } else {
                message.error('Something went wrong!')
                Modal.error({
                    title: 'Something went wrong!',
                    content:
                        'Make sure that the selected date-range match with the first and last dates in the CSV and PDF file. (Raido may sometimes skip days). Please contact us or check out our youtube tutorial if you keep experiencing troubles!',
                })
            }
        } catch (e) {
            message.error(`Something went wrong: ${e.data?.detail || 'Invalid Roster period'}`)
            Modal.error({
                title: 'Something went wrong!',
                content:
                    'Make sure that the selected date-range match with the first and last dates in the CSV and PDF file. (Raido may sometimes skip days). Please contact us or check out our youtube tutorial if you keep experiencing troubles!',
            })
        }
        setLoading(false)
    }

    const uploadFile = async (options) => {
        const { file } = options
        const url = options.action
        const fmData = new FormData()
        const config: AxiosRequestConfig = {
            headers: {
                ...getCommonHeaders(),
                ...getAuthHeader(),
                'content-type': 'multipart/form-data',
            },
        }
        fmData.append('file', file)
        const res = await axios.post(url, fmData, config)
        return res.data
    }

    const uploadPDFFile = async (options) => {
        const { onSuccess, onError } = options
        try {
            const data = await uploadFile(options)
            setPdfFileUid(data.uid)
            onSuccess('ok')
        } catch (err) {
            console.log('Error: ', err)
            onError({ err })
        }
    }

    const uploadCSVFile = async (options) => {
        const { onSuccess, onError } = options
        try {
            const data = await uploadFile(options)
            setCsvFileUid(data.uid)
            onSuccess('ok')
        } catch (err) {
            console.log('Error: ', err)
            onError({ err })
        }
    }

    const props = (type) => {
        let uploadFun = uploadPDFFile
        let updator = setPdfFileUid
        if (type === 2) {
            uploadFun = uploadCSVFile
            updator = setCsvFileUid
        }
        return {
            action: `${API_URL}/roster/file/2020-01-12`,
            customRequest: uploadFun,
            onChange(info) {
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`)
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`)
                } else if (info.file.status === 'removed') {
                    updator(null)
                }
            },
        }
    }

    const getPdfFile = (e: any) => {
        return pdfFileUid
    }

    const getCsvFile = (e: any) => {
        return csvFileUid
    }

    return (
        <Modal visible={visible} onCancel={closeModal} footer={[]} destroyOnClose>
            <Form name="roster_config" {...formItemLayout} onFinish={onFinish}>
                <Form.Item name="range-picker" label="Roster Range" {...rangeConfig}>
                    <RangePicker />
                </Form.Item>
                <Form.Item
                    name="data_file"
                    label="pdf file (.pdf)"
                    // rules={[
                    //     {
                    //         // type: 'string' as const,
                    //         required: true,
                    //         message: 'Please upload 1 PDF file!',
                    //     },
                    // ]}
                    valuePropName="fileList"
                    getValueFromEvent={getPdfFile}
                >
                    <Upload {...props(1)} accept=".pdf">
                        <Button icon={<CloudUploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    {pdfFileUid ? (
                        <Tag color="success">Uploaded</Tag>
                    ) : (
                        <Tag color="error">Missing</Tag>
                    )}
                </Form.Item>
                <Form.Item
                    name="data_file"
                    label="data file (.csv)"
                    // rules={[
                    //     {
                    //         // type: 'string' as const,
                    //         required: true,
                    //         message: 'Please upload 1 CSV file!',
                    //     },
                    // ]}
                    valuePropName="fileList"
                    getValueFromEvent={getCsvFile}
                >
                    <Upload {...props(2)} accept=".csv">
                        <Button icon={<CloudUploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    {csvFileUid ? (
                        <Tag color="success">Uploaded</Tag>
                    ) : (
                        <Tag color="error">Missing</Tag>
                    )}
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        xs: { span: 24, offset: 0 },
                        sm: { span: 16, offset: 8 },
                    }}
                >
                    <Button
                        className="roster-submit-btn"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Submit
                    </Button>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        xs: { span: 24, offset: 0 },
                        sm: { span: 16, offset: 8 },
                    }}
                >
                    <Button className="roster-submit-btn" onClick={closeModal}>
                        <h4>Cancel</h4>
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
