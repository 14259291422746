import { message, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { APIFunctions } from '../../lib/apiFunctions'
import { IFileList } from '../../lib/interfaces'
import { Box, Chip } from '@mui/material'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import dayjs from 'dayjs'

export default function DocFileItem(
    originNode,
    file: IFileList,
    currFileList: IFileList[],
    reportChange,
    dateClicked,
    parentVisible,
    apiFunctions: APIFunctions,
    userId: string,
    setFileList: (i: IFileList[]) => void
) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isIncluded, setIsIncluded] = useState<boolean>(
        file?.include_report !== undefined && file?.include_report !== null
            ? file?.include_report
            : true
    )

    useEffect(() => {
        setIsIncluded(
            file?.include_report !== undefined && file?.include_report !== null
                ? file?.include_report
                : true
        )
    }, [file, parentVisible])

    async function changeIncludeDocInReport() {
        setIsLoading(true)
        try {
            const data = await apiFunctions.changeIncludeReportSwitch(userId, file.uid)
            setFileList(
                currFileList.map((f) => {
                    if (f.uid === file.uid) {
                        return { ...f, include_report: data.is_included }
                    }
                    return f
                })
            )
            setIsIncluded(data.is_included)
            if (reportChange)
                reportChange({
                    targetDate: dateClicked,
                    action: data.is_included ? 'include' : 'exclude',
                    fileId: file.uid,
                    isIncluded: data.is_included,
                    newTotal: currFileList.length,
                    newActive:
                        currFileList.filter((f) => f.include_report).length +
                        Number(data.is_included)
                            ? 1
                            : -1,
                })
        } catch (ex) {
            message.error('Could not switch file')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box
            className="autotest-document-render"
            sx={{
                height: '80%',
                position: 'relative',
            }}
        >
            {originNode}
            {file?.is_location_statement && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Chip
                        variant="filled"
                        size="small"
                        avatar={<WhereToVoteIcon />}
                        label={
                            file?.created_on
                                ? `GS ${dayjs(file?.created_on).format('HH:mm')}`
                                : 'GeoSnapshot'
                        }
                    />
                </Box>
            )}
            <Switch
                loading={isLoading}
                onChange={changeIncludeDocInReport}
                checked={isIncluded}
                checkedChildren="Included"
                unCheckedChildren="Excluded"
            />
        </Box>
    )
}
